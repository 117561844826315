.login-image-wrapper{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;

  overflow: hidden;
  opacity: 0.1;
  transition: 3s;
  &.active{
    opacity: 1;
    z-index: 2;
  }
}
.login-wrapper{
  background-image: url("../../../assets/images/login/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.login__container {
  height: fit-content;
  background: var(--theme-back-ground);
  .mb-40 {
    margin-bottom: 20px !important;
  }

  .left-col {
    display: flex;
    align-items: center;
    padding: 40px;
    background: var(--theme-back-ground);
    .login-form {
      width: 400px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      .btn-back{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--normal-text-color);

        display: flex;
        align-items: center;
        gap: 8px;
      }
      .login-title {
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: var(--normal-text-color);
        text-align: left;
        margin-bottom: 10px;
      }

      .sub__login-title {
        font-family: Inter;
        font-size: 17.2px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.86px;
        color: var(--normal-text-color);
        text-align: justify;
      }

      .form-input-container {
        width: 100%;

        .form-title {
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: var(--login-form-title-text-color);
          text-align: left;
          margin-bottom: 7px;
          padding-left: 20px;
        }

        .input-text-form {
          width: 100%;

          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          color: var(--normal-text-color);
          background: none;
          outline: none;
          border-radius: 110px;
          padding: 13px 20px;
          border: 2px solid var(--normal-text-color);
          transition: all 0.3s linear;
          &:focus {
            border: 2px solid #75D3F6;
          }
        }
      }

      .option-login {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .remember {
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: var(--normal-text-color);
          display: flex;
          cursor: pointer;
          align-items: center;

          div.checked {
            width: 15px;
            height: 15px;
            background-image: url('../../../assets/images//login/unchecked.png');
            background-size: 100% 100%;
            margin-right: 5px;
          }
          div.unchecked {
            width: 15px;
            height: 15px;
            background-image: url('../../../assets/images//login/checked.png');
            background-size: 100% 100%;
            margin-right: 5px;
          }
        }

        .forget-password {
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #00F6FF;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        .text {
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #00F6FF;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .login-button {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        color: var(--login-form-button-text-color);
        background: #B5B5B5;
        border-radius: 110px;
        transition: all 0.3s linear;
        &:hover {
          background: #c7c7c7;
        }
      }

      .other-login {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 23px;

        .other-login-title {
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #8E8E8E;
        }

        .other-login-btn {
          width: 39px;
          height: 39px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .register {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.8px;
        color: var(--normal-text-color);
        text-align: left;
        transition: all 0.3s linear;
        .register-now {
          color: #00F6FF;
          margin-left: 5px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .right-col {
    background: var(--theme-back-ground);
  }
}

@media screen and (min-height: 800px) {
  .login__container {
  
    .left-col {
      display: flex;
      align-items: center;
  
      .login-form {
        .login-title {
          margin-bottom: 25px;
        }
  
        .form-input-container {
          width: 100%;
        }
  
        .login-button {
          // margin-bottom: 30px;
        }
  
        .other-login {
          .other-login-btn {
            width: 39px;
            height: 39px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}