.modal-payment{
    .ant-modal-content{
        background: rgb(2, 2, 14);
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 32px 46px;
        border-radius: 10px;
        border: 2px solid var(--light-border-color);
        background: rgba(0, 0, 0, 0.60);
        .ant-modal-close{
            top: 0;
            right: 0;
            transform: translateX(50%) translateY(-50%);
        }
        .modal-payment-content{
            .modal-payment-header{
                .title{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 36px;
                    color: #FFFFFF;
                }
            }
        }
    }
}