.plugin-collapse-container{
    background: rgba(0, 0, 0, 0.30);
    border-radius: 5px;
    .ant-collapse-header{
        padding: 9px 7px !important;
        align-items: center;
        .ant-collapse-header-text{
            flex: auto !important;
            color: #FFF;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            .text-add{
                cursor: pointer;
                color: #FFF;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
                &.disabled{
                    opacity: 0.5;
                    user-select: none;
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }
        }
    }
    .ant-collapse-content{
        .ant-collapse-content-box{
            padding: 17px 10px;
            padding-top: 5px;
            .plugin-collapse-content{
                display: flex;
                flex-direction: column;
                gap: 12px;
                .motion-info{
                    display: flex;
                    align-items: center;
                    gap: 24px;
                    padding: 11px;
                    border-radius: 5px;
                    background: rgba(0, 0, 0, 0.40);
                    .image{
                        border-radius: 5px;
                        background: #D9D9D9;
                        width: 73px;
                        height: 73px;
                    }
                    .motion-name-info{
                        display: flex;
                        flex: auto;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;
                        .name{
                            color: #FFF;
                            font-family: "Inter";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                        .change{
                            cursor: pointer;
                            color: #00E5FF;
                            font-family: "Inter";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}